import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const getHolidayList = () => {
  var holidayList = [];
  var year = (new Date()).getFullYear();

  holidayList.push(new Date(year, 0, 1)); // Ano novo 
  holidayList.push(new Date(year, 3, 21));  // Tiradentes
  holidayList.push(new Date(year, 4, 1)); // Dia do trabalho
  holidayList.push(new Date(year, 8, 7)); // Dia da Independência do Brasil
  holidayList.push(new Date(year, 9, 12));  // Nossa Senhora Aparecida
  holidayList.push(new Date(year, 10, 2)); // Finados
  holidayList.push(new Date(year, 10, 15)); // Proclamação da República
  holidayList.push(new Date(year, 11, 25)); // Natal

  // Feriados móveis
  var x, y;
  var a, b, c, d, e;
  var day, month;

  if (year >= 1900 && year <= 2099) {
      x = 24;
      y = 5;
  } else if (year >= 2100 && year <= 2199) {
      x = 24;
      y = 6;
  } else if (year >= 2200 && year <= 2299) {
      x = 25;
      y = 7;
  } else {
      x = 24;
      y = 5;
  }

  a = year % 19;
  b = year % 4;
  c = year % 7;
  d = (19 * a + x) % 30;
  e = (2 * b + 4 * c + 6 * d + y) % 7;

  if ((d + e) > 9) {
      day = (d + e - 9);
      month = 3;
  } else {
      day = (d + e + 22);
      month = 2;
  }

  var pascoa = new Date(year, month, day);
  var sextaSanta = new Date(pascoa);
  sextaSanta.setDate(pascoa.getDate() - 2);
  var carnaval = new Date(pascoa);
  carnaval.setDate(pascoa.getDate() - 47);
  var corpusChristi = new Date(pascoa);
  corpusChristi.setDate(pascoa.getDate() + 60);

  holidayList.push(pascoa);
  holidayList.push(sextaSanta);
  holidayList.push(carnaval);
  holidayList.push(corpusChristi);

  return holidayList;
}

