export default [
  {
    exmenuid: '100000000',
    title: 'HOME',
    route: 'home',
    icon: 'HomeIcon',
    children: '',
  },
  {
    exmenuid: '100000000',
    title: 'Doc',
    // route: 'documentoNav',
    route: { name: 'documentoNav', params: { path: 'teste', id: 'teste2' } },
    icon: 'HomeIcon',
    children: '',
  },
  // {
  //   title: 'Forms Elements',
  //   icon: 'CopyIcon',
  //   children: [
  //     {
  //       title: 'Input',
  //       route: 'forms-element-input',
  //     },
  //     {
  //       title: 'Input Group',
  //       route: 'forms-element-input-group',
  //     },
  //     {
  //       title: 'Input Mask',
  //       route: 'forms-element-input-mask',
  //     },
  //     {
  //       title: 'Textarea',
  //       route: 'forms-element-textarea',
  //     },
  //     {
  //       title: 'Auto Suggest',
  //       route: 'extensions-auto-suggest',
  //     },
  //     {
  //       title: 'Checkbox',
  //       route: 'forms-element-checkbox',
  //     },
  //     {
  //       title: 'Radio',
  //       route: 'forms-element-radio',
  //     },
  //     {
  //       title: 'Switch',
  //       route: 'forms-element-switch',
  //     },
  //     {
  //       title: 'Select',
  //       route: 'forms-element-select',
  //     },
  //     {
  //       title: 'Vue Select',
  //       route: 'extensions-vue-select',
  //     },
  //     {
  //       title: 'Spinbutton',
  //       route: 'forms-element-spinbutton',
  //     },
  //     {
  //       title: 'File Input',
  //       route: 'forms-element-file-input',
  //     },
  //     {
  //       title: 'Quill Editor',
  //       route: 'extensions-quill-editor',
  //     },
  //     {
  //       title: 'Form Datepicker',
  //       route: 'forms-element-datepicker',
  //     },
  //     {
  //       title: 'Form Timepicker',
  //       route: 'forms-element-timepicker',
  //     },
  //     {
  //       title: 'Date Time Picker',
  //       route: 'extensions-date-time-picker',
  //     },
  //     {
  //       title: 'Form Rating',
  //       route: 'forms-element-rating',
  //     },
  //     {
  //       title: 'Form Tag',
  //       route: 'forms-element-tag',
  //     },
  //   ],
  // },
]
